import { Percent } from '@uniswap/sdk-core'

export enum SlippageValidationResult {
  TooLow,
  TooHigh,
  Valid,
}

export const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(105, 10000)
export const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(150, 1000)

export default function validateUserSlippageTolerance(userSlippageTolerance: Percent) {
  if (userSlippageTolerance.lessThan(MINIMUM_RECOMMENDED_SLIPPAGE)) {
    return SlippageValidationResult.TooLow
  } else if (userSlippageTolerance.greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE)) {
    return SlippageValidationResult.TooHigh
  } else {
    return SlippageValidationResult.Valid
  }
}
