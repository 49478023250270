
const tradeFeeConfig = {

  referralComissionPercent: 3,
  platfromFeePercent: 7,
  platformFeeWalletAddress:"0x91ADbA1CEa43C1433019c83728bB0BAb8ACed168",
  defaultReferralAddress: "0x7A6357AC6c5850c6Db2e60839a0B7c288056b895",
  subgraph:"https://api.thegraph.com/subgraphs/name/shahzeb8285/hotkeyswapcustomsettings",
}

export default tradeFeeConfig
