import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { DEPRECATED_RPC_PROVIDERS, RPC_PROVIDERS } from 'constants/providers'
import { useFallbackProviderEnabled } from 'featureFlags/flags/fallbackProvider'
import { useContract } from 'hooks/useContract'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import referralmanagerABI from "constants/abi/referralmanagerABI.json";
import { REFERRAL_MANAGER_ADDRESS } from 'constants/chains'
import { ethers } from 'ethers'
import tradeFeeConfig from 'lib/utils/tradefeeconfig'
import axios from "axios"


export const useMyCustomFeeSettings = () => {
  const { account: walletAddress } = useWeb3React()



  const [feeSettings, setFeeSettings] = useState({
    platformFee: tradeFeeConfig.platfromFeePercent,
    referralFee: tradeFeeConfig.referralComissionPercent,
  })

  const fetchCustomSettings = async () => {
    const payload = `query CustomComissionSettings {
      comissions(where:{user:"${walletAddress?.toLowerCase()}"}){
        referralComission
        platformComission
      }
    }`


    const endpoint = tradeFeeConfig.subgraph
    const headers = {
      "content-type": "application/json",
    };
    const graphqlQuery = {
      "operationName": "fetchAuthor",
      "query": payload,
      "variables": {}
    };

    const options = {
      "method": "POST",
      "headers": headers,
      "body": JSON.stringify(graphqlQuery)
    };

    const response = await fetch(endpoint, options);
    const data = await response.json();
    if (data && data.data && data.data.comissions && data.data.comissions.length>0) {
      const rawPlatformComission = Number(data.data.comissions[0].platformComission)
      const rawReferralComission = Number(data.data.comissions[0].referralComission)
      const finalFee = { referralFee: rawReferralComission / 1000, platformFee: rawPlatformComission / 1000 }

      setFeeSettings(finalFee)
    }

    console.log(data.errors);


  }

  useEffect(() => {
    if (walletAddress) {
      fetchCustomSettings()
    }

  }, [walletAddress])

  return { feeSettings }
}
