

type ChainConfig = {
  router: string
  creationBlock: number
  weth: string
}

const WETH_NOT_SUPPORTED_ON_CHAIN = '0x0000000000000000000000000000000000000000'

const CHAIN_CONFIGS: { [key: number]: ChainConfig } = {
  // mainnet
  [1]: {
    router: '0x60C352Fb70598f548C5B6b27c2484dd0323eC793',
    weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    creationBlock: 19291941    ,
  },
  // goerli
  [5]: {
    router: '0xF7EB900A10c403E5C8E9c011D9B789c34A87C5bc',
    weth: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
    creationBlock: 10584079,
  },
  // sepolia
  [11155111]: {
    router: '0x287a05C8e21B40647D9Ec528534E390EdC814b57',
    weth: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    creationBlock: 5347446,
  },
  // polygon
  [137]: {
    router: '0x7C7AC756A2EBA0fe6a78914803a5b7322e2B820F',
    weth: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    creationBlock:  53864489
    ,
  },
  // //polygon mumbai
  // [80001]: {
  //   router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
  //   weth: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
  //   creationBlock: 35176052,
  // },
  //optimism
  [10]: {
    router: '0x3B60AaB92DD62444c9cFFeb3678DD34BB587A3Be',
    weth: '0x4200000000000000000000000000000000000006',
    creationBlock: 116552252 ,
  },
  // optimism goerli
  // [420]: {
  //   router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
  //   weth: '0x4200000000000000000000000000000000000006',
  //   creationBlock: 8887728,
  // },
  // arbitrum
  [42161]: {
    router: '0xa9Fb6e07099577Ea0990338c189533A2DDB09e50',
    weth: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    creationBlock:183741691 ,
  },
  // // arbitrum goerli
  // [421613]: {
  //   router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
  //   weth: '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
  //   creationBlock: 18815277,
  // },
  // celo
  [42220]: {
    router: '0x1172398fc61409a3A956E36C9CeD328076E1B06e',
    weth: WETH_NOT_SUPPORTED_ON_CHAIN,
    creationBlock: 24205613 ,
  },
  // // celo alfajores
  // [44787]: {
  //   router: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
  //   weth: WETH_NOT_SUPPORTED_ON_CHAIN,
  //   creationBlock: 17566658,
  // },
  // binance smart chain
  [56]: {
    router: '0x6cb63a4950f2d2fb9FB612Bfbb74109ac952a2c9',
    weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    creationBlock: 36388134,
  },
  // avalanche
  [43114]: {
    router: '0x18E03589e61bb29C4af86dc3573C120C5a61B625',
    weth: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    creationBlock: 42046923,
  },
  // // base goerli
  // [84531]: {
  //   router: '0xabcea2a2fea2a8dd0b3c9e7e8bf3cce4bf22ea7b',
  //   weth: '0x4200000000000000000000000000000000000006',
  //   creationBlock: 6915289,
  // },
  // base mainnet
  [8453]: {
    router: '0x402039591376Bd3CdD423d0AE72DB0Dd5DA9EDa6',
    weth: '0x4200000000000000000000000000000000000006',
    creationBlock: 10955587,
  },
}

export const CUSTOM_UNIVERSAL_ROUTER_ADDRESS = (chainId: number): string => {
  if (!(chainId in CHAIN_CONFIGS)) throw new Error(`Universal Router not deployed on chain ${chainId}`)
  return CHAIN_CONFIGS[chainId].router
}
